header{ background: #ffffff78; top: 0; width: 100%; z-index: 9; box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.05);position: absolute;
  @include media-breakpoint-down(md){position: relative; background: #fff; }
  #menu-area{
    @include media-breakpoint-down(md){ margin: 0px 0;}
    .navigation-wrap{padding:0 15px;
      @include media-breakpoint-down(sm){padding:0;}
    }
    .navbar{ margin: 0px 0 0 0;padding: 0;
      @include media-breakpoint-down(md){display: inherit;}
    }
    .logo{padding: 0;font-size: 25px; width: auto; margin: 8px 0;
      img{height: 83px;}
      .phone{padding-left: 8px; font-size: 15px;    font-weight: 700;
        @media(max-width: 991px){
          color: #000;
        }
      }
      @include media-breakpoint-down(sm){font-size:18px;}
      span{ background: #fff;color: $primary;border-radius: 50%; line-height: 50px;
        width: 50px;height:50px;text-align: center;margin-right: 5px;
        @include media-breakpoint-down(sm){line-height: 40px;width: 40px;height:40px;}
      }
      a{ color: #fff; display: flex; align-items: center; }
    }
  }


  #menu-area .navbar-collapse{justify-content: space-between;flex-wrap: wrap;padding: 15px 0;}
  #menu-area .navbar .right-nav{/* flex: 1; */}
  #menu-area .navbar-toggler {float: right;border: none;padding-right: 0;}
  #menu-area .navbar-toggler:active,
  #menu-area .navbar-toggler:focus {outline: none;}
  #menu-area .navbar-light .navbar-toggler-icon {width: 24px;height: 17px;background-image: none;position: relative;
    border-bottom: 1px solid #000;transition: all 300ms linear;opacity: .8;}
  #menu-area .navbar-light .navbar-toggler-icon:after,
  #menu-area .navbar-light .navbar-toggler-icon:before{width: 24px;position: absolute;height: 1px;background-color: #000;top: 0;left: 0;
    content: '';z-index: 2;transition: all 300ms linear;}
  #menu-area .navbar-light .navbar-toggler-icon:after{top: 8px;}
  #menu-area .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {transform: rotate(45deg);}
  #menu-area .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {transform: translateY(8px) rotate(-45deg);}
  #menu-area .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {border-color: transparent;}
  #menu-area .nav-link{color:#fff; transition: all 200ms linear;    color: #fff;
    transition: all 200ms linear;font-size: 15px; font-family: 'Oswald', sans-serif;
    padding: 15px 18px;letter-spacing: .5px;text-transform: uppercase;position: relative;
  @media(max-width: 991px){
    color: #000;
  }
  }
  #menu-area .nav-link img{width: 30px;margin-right: 5px;}
  #menu-area .nav-item:hover .nav-link{color: var(--white);background-color: var(--primary-color); border-radius: var(--border-radius-custom);}
  #menu-area .nav-item.active .nav-link{color: var(--secondary-color);}
  #menu-area .nav-item{position: relative;transition: all 200ms linear;}
  #menu-area .nav-item.megamenu{position: static;}
  #menu-area .nav-item .dropdown-menu {transform: translate3d(0, 10px, 0);visibility: hidden;opacity: 0;max-height: 0;display: block;padding: 0;margin: 0;transition: all 200ms linear;}
  #menu-area .nav-item.show .dropdown-menu {opacity: 1;visibility: visible;max-height: 999px;transform: translate3d(0, 0, 0);width: 250px;border-radius: 0;}
  #menu-area .nav-item.megamenu .dropdown-menu {width: 100%;}
  #menu-area .dropdown-menu {padding: 10px 15px !important;margin: 0;letter-spacing: 0;color: var(--white);background-color: var(--gray700);border: none;box-shadow: var(--shadow);transition: all 200ms linear;}
  #menu-area .dropdown-item {padding: 4px 0;color: var(--gray200);border-radius: 0;transition: all 200ms linear;font-weight: 400;font-size: 1rem;line-height: 1.4;white-space: pre-wrap;}
  #menu-area .dropdown-item:hover,
  #menu-area .dropdown-item:focus {color: var(--primary-color);background-color: transparent;}
  #menu-area .dropdown-header{padding: 0 0 10px;font-weight: 700;color: var(--primary-color);font-size: 1.4rem;}
  #menu-area .navbar-light .navbar-toggler{padding-left: 8px; margin-top: 22px; }
  #menu-area.add-sticky{position: fixed;top: 0;z-index: 9;width: 100%;}
  #menu-area.add-sticky form{display: block;}
}
