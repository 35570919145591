.slider-area { position: relative;
&::after{top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  position: absolute;
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-image: url(../../assets/images/slider/full-page-banner-bdr.png);
  @include media-breakpoint-down(sm){bottom: -1px;}
}
  .carousel-item { position: relative;
    img{ width: 100%; background-size: cover;}
    }



  //.carousel {  position: relative;width: 100%;overflow: hidden;
  //  .carousel-item { position: relative;
  //    img{ width: 100%; background-size: cover;
  //      @include media-breakpoint-down(xs){ width: auto; background-size: inherit;height: 550px; }
  //      @include media-breakpoint-down(md){ width: auto; background-size: inherit;height:480px; }
  //    }
  //    &:after {
  //     // content: '';
  //      position: absolute;
  //      left: 0;
  //      right: 0;
  //      top: 0;
  //      bottom: 0;
  //      background: rgba(0, 0, 0, 0.5);
  //    }
  //    .carousel-caption { top: 110px;
  //      @include media-breakpoint-down(xs){right: 0; left: 0; top:10%; }
  //      .content {
  //        display: block; text-align:left ;
  //        // padding: 430px 0 215px;
  //        position: relative;
  //        z-index: 999;
  //        overflow: hidden;
  //        vertical-align: middle;
  //        @include media-breakpoint-down(xs){padding: 50px 0 50px;text-align: center;}
  //        h2 {
  //          color: #fff;
  //          font-size: 60px;
  //          font-family: "Playfair Display", serif;
  //          font-weight: 400;
  //          margin: 0 0 45px;
  //          @include media-breakpoint-down(xs){font-size: 36px;}
  //          @include media-breakpoint-down(md){font-size: 36px;}
  //          span {
  //            color: $primary;font-family: "Playfair Display", serif;
  //          }
  //        }
  //        p {
  //          font-size: 24px;
  //          color: #fff;font-family: "Poppins", sans-serif;
  //          margin: 0 0 50px;
  //          @include media-breakpoint-down(md){font-size: 19px;}
  //        }
  //      }
  //    }
  //
  //
  //  }
  //  .carousel-indicators { margin: 0;list-style: none;width: 0;
  //    z-index: 9;width: auto; display: flex;
  //    @include media-breakpoint-down(xs){display: none;}
  //    @include media-breakpoint-down(md){display: none;}
  //    li {text-indent: 0;position: relative;height: 40px;line-height: 40px;background-color: transparent;display: block;
  //      border: none;border-radius: 0; cursor: pointer;font-size: 16px; text-align: center;
  //      margin: 0 0 25px 0;color: #fff;font-family: "Poppins", sans-serif; background: rgba(33, 33, 33, 0.5);padding: 0 2px;
  //      span.number {display: inline-block; }
  //      span.con {display: inline-block;position: relative;padding-left: 42px;
  //        &:after {
  //          content: '';
  //          position: absolute;
  //          left: 0;
  //          top: 50%;
  //          height: 1px;
  //          background: #cf9c67;
  //          width: 26px;
  //        }
  //      }
  //      &:first-child {
  //        margin-top: 0;
  //      }
  //    }
  //    li.active {
  //      background: #004cef87;
  //    }
  //  }
  //}
}
