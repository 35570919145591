.innerbaner { position: relative; background: url("../../assets/images/inner.jpg") no-repeat center center;
  background-size: cover; padding: 150px 120px;
  &::after{top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    position: absolute;
    background-size: cover;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-image: url(../../assets/images/slider/full-page-banner-bdr.png);}
  h3{color: #fff;font-size: 36px;line-height: 24px;font-weight: 300;margin-bottom: 15px;}
   ul {list-style: none;margin: 0;padding: 0;
     li{display: inline-block;font-size: 14px;line-height: 24px;font-weight: 400;text-transform: capitalize;color: #9a9ea2;
       &:after {content: "/";margin-left: 10px;margin-right: 10px;}
       &:last-child{
         &:after{ display: none;}
       }
      a{display: inline-block;color:#d9d9d9;}
     }
     .active {color: #fff;}
  }
}
