//Please import All plugins in plugins.scss file
@import "./plugins";

*{
  font-family: 'Open Sans', sans-serif;
}
a:hover{ text-decoration: none;}
.gray-bg {background-color:#f8f8f8;}
.section-heading{ margin-bottom: 30px;
  @include media-breakpoint-down(sm){margin-bottom: 15px;}
  h1{ font-size: 36px;font-weight: bold;margin-bottom: 18px; color: $primary;
    @include media-breakpoint-down(sm){font-size: 1.5rem;}
  }
}
.btn4 {display: inline-block;position: relative;line-height: 50px;background: #0f4b85;font-weight: 300;text-transform: uppercase;
  color: #fff;padding: 0 27px;overflow: hidden;vertical-align: middle;
  &:before {content: '';position: absolute;top: 0;bottom: 100%;left: 0;right: 100%;height: 0;background: #3b70fc;transition: all 0.5s;}
  &:after {content: '';position: absolute;top: 100%;bottom: 0;right: 0;left: 100%;height: 100%;background: #3b70fc;transition: all 0.5s;}
  &:hover {
    &:before {right: 50%;top: 0;bottom: 0;height: 100%;}
    &:after {left: 50%;top: 0;bottom: 0;}
    span {color: #fff;}
  }
  span {position: relative;z-index: 999;color: #fff;transition: all 0.5s;}
}


//------------------intro-----------------
.intro-section{padding: 55px 0 60px;
  @include media-breakpoint-down(sm){padding:15px 0;}
  .intro-caption {padding: 15px 0 20px;}
  .intro-thumb {overflow: visible;margin: 0 0 30px;padding: 0 30px 30px 0;
    &::before {height: 90%;top: 25px;left: 50px;right: 0px;bottom: 0;content: "";position: absolute;
      background-color: #9f9fb3;width: 80%;}
    img{position: relative;box-shadow: 0 5px 10px rgba(0,9,128,0.035), 0 7px 18px rgba(0,9,128,0.05);-o-box-shadow: 0 5px 10px rgba(0,9,128,0.035),0 7px 18px rgba(0,9,128,0.05);
      -ms-box-shadow: 0 5px 10px rgba(0,9,128,0.035),0 7px 18px rgba(0,9,128,0.05);
      -moz-box-shadow: 0 5px 10px rgba(0,9,128,0.035),0 7px 18px rgba(0,9,128,0.05);
      -webkit-box-shadow: 0 5px 10px rgba(0,9,128,0.035), 0 7px 18px rgba(0,9,128,0.05); width: 100%;
    }
  }

}

//-----------------------Services-------------------
.section-heading {margin-bottom: 53px;
  .title {font-size: 36px;font-weight: bold;margin-bottom: 18px;color: #0f4b85;}
  .heading-icon {clear: both;width: 215px;height: 12px;display: block;margin: 0 auto 18px;background-size: 100%;
    background-position: center;background-repeat: no-repeat;background-image: url(../../assets/images/heading-bg.png);
  }
  p {display: block;margin: 0 auto;width:90%;
  }
}
.service{padding: 55px 0 60px;
  .our-srvices{margin: 0 0 30px;border-radius: 5px;padding: 30px 25px 30px;background-color: #f7fbff;
    &:hover{box-shadow: 0 5px 10px rgba(0,9,128,0.035), 0 7px 18px rgba(0,9,128,0.20); background: #4b6fff; color:#fff;}
    .svg-icon {height: 40px;font-size: 40px;margin: 0 0 23px; display: flex; }
    .text{
      .title {margin: 0 0 22px; font-size: 22px;}
      p{ margin-bottom: 20px;}
      .btn4{ line-height: 40px; text-transform: capitalize; padding: 0 15px;}
    }
  }
}

//-----------------------gallery--------------------
.gallery{padding: 55px 0 60px;
  .img-hover-zoom {overflow: hidden; position: relative; margin-bottom: 30px;}
  .img-hover-zoom img {transition: transform .5s ease; width: 100%;}
  .img-hover-zoom:hover img {transform: scale(1.3);}
}

//-----------------------subscribe--------------------
.subscribe-bg{padding: 55px 0 60px;  background: url("../../assets/images/subscribe.png") no-repeat; background-size: cover;background-position: center; margin-top: 50px;
.subscribe{
  .center-content{ align-items: center;height: 100%;flex-direction: column;
    .form-group {width: 100%;font-size: 18px;margin-bottom: 0; position: relative; display: flex;
      @include media-breakpoint-down(sm){display: inherit;}
    input{background-color: #fff;padding: 30px 35% 30px 35px;border-radius: 50px;
      border: 1px solid #f1f1f1;font-size: 18px;-webkit-box-shadow: none;box-shadow: none;margin: 0;height: auto;
      @include media-breakpoint-down(sm){height: 50px;margin-bottom: 20px;}

    }
      .button-primary{ position: absolute;right: 11px;top: 9px;
        @include media-breakpoint-down(sm){position: inherit; top: 0; bottom: 0; right: 0;}
      .btn4{padding: 10px 55px;font-weight: 200;border-radius: 34px;letter-spacing: 3px; border: 0; outline: none;
        @include media-breakpoint-down(sm){ margin: auto;padding: 1px 20px;letter-spacing: inherit;text-transform: capitalize;width: 100%;}
      }
      }
    }
  }
}
  .socials-lists{ margin-top: 50px;
    ul{padding: 0;list-style: none;display: flex;justify-content: center;
      li{ font-size: 14px; margin: 0 4px;    text-align: center;
        a{ text-align: center;
          i{height: 50px;width: 50px;background-color: #357fef;border-radius: 50px;font-size: 20px;color: #fff;
            transition: all 800ms;border: 2px solid #fff; line-height: 50px; text-align: center;
          }
        }
      }
    }

  }
  .socials-lists ul li a:hover i {
    background-position: 0 -90px;
    background-color: #fff;
    color: #357fef;
    border: 1px solid #357fef;
  }
}

//--------------------------------Contact -----------------------
.contact-area{ padding-top: 80px;
  @include media-breakpoint-down(sm){padding-top: 0;}
  .contact-info {background: #f5f5f587;padding: 50px 30px;text-align: center;margin-bottom: 30px;border: 2px solid #edf1f4;
    transition: all 0.3s linear 0s; margin-bottom: 40px;
    .icon {
      box-shadow: 0px 6px 47px 3px rgba(37, 37, 37, 0.05);width: 80px;height: 80px; color: #a3bbc8; font-size: 33px;
      border-radius: 30px;text-align: center;line-height: 80px;margin: 0 auto 30px;transition: all 0.3s linear 0s;
      img{ width: 100%;}

    }
    .details{
      h5 {font-size: 20px;line-height: 1.2;}
      ul {list-style: none;margin: 0;padding: 0;
        li {display: inline-block;color: #565872;font-size: 16px;line-height: 30px;
        }

      }
    }
  }
  .active {
    border-color: #a3bbc8;
  }
  .contact-form-wrap { margin: 40px 0;
    @include media-breakpoint-down(sm){margin-top: 0;}
    form{margin: 0 0 15px 0;
      @include media-breakpoint-down(sm){margin: 0 0 15px 0;}
      .form-group {position: relative;margin-bottom: 25px;
        input, textarea {width: 100%;padding: 15px 50px 15px 30px;}

      }
    }

    .form-icon {position: absolute;right: 30px;top:50%;transform: translateY(-50%); font-size: 20px;
    }
  }
}
.form-control{height: 55px;box-shadow: none;outline: 0;border-radius: 5px;border: 1px solid #edf2f7;
  background-color: #e2e8f0;font-size: .9rem;}
.img-res{ width: 100%;}


//----------------------------------about-area-------------------
.about-area{ padding: 40px 0 ;
  img{ width: 100%;}
  .single-proses {

    // text-align: center;
    text-align: left;

    margin: 10px 0px 25px;

  }
  .proses-icon a {
    width: 100px;
    height: 100px;
    line-height: 97px;
    font-size: 36px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 30px;
    background:#fff; color: #004e7a;
  }
  .proses-icon {
    position: relative;text-align: center;
    display: inline-block;
  }
  .point-view {
    width: 40px;
    height: 40px;
    line-height: 37px;
    font-size: 16px;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: -10px; background: #004e7a;
  }
  .proses-text p {
    margin-bottom: 0px; font-size: 17px;
  }
  h4 {color: #2a354e;font-size: 30px;font-weight: 700;
    text-decoration: none;
    text-transform: capitalize;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
  }
.serviceslide{ transition: all .5s;
  .item{width:100%;
    img{width: 100%;height: 365px;
      object-fit: cover;
      @media(max-width: 767px){
        height: auto;  
      }
    }
  }
  &:hover{
    .owl-dots{opacity: 1;}
    .owl-nav{opacity: 1;}
  }
  .owl-nav{position:absolute; top: 50%; transform: translateY(-50%);width: 100%; opacity: 0;
    display: flex;
    justify-content: space-between;
    button{
      &:focus{outline: none;}
    }
    span{color: #fff; font-size: 60px;}
  .owl-prev{margin-left: 20px !important;}
  .owl-next{margin-right: 20px !important;}
  }
  .owl-dots{position:absolute; bottom: 15px; right: 15px;opacity: 0;
  .owl-dot{border: none; color: #fff;width: 11px; background-color: #fff; opacity: .5;
    height: 11px; border-radius: 50%; margin-right: 6px;
  &:focus{outline: none;}
  &:last-child{margin-right: 0;}
  }
  }
  .owl-dot.active{opacity: 1;}
}
  @media(max-width: 767px){
    .rowarea2{flex-direction: column-reverse;}
  }
}
.about1{
  background: #f2f2f2; padding: 40px 0;
  h3{ margin-bottom: 25px;}
  ul.stylelisting {list-style-type: none;margin-left: 0!important; padding: 0;
    li {position: relative;padding-left: 22px!important;line-height: 40px;border: none!important;font-weight: 500;transition: all .5s ease;
      font-size: 17px;
      &:before {position: absolute;top: 0;left: 0;z-index: 0;content: "\f05d";font-family: FontAwesome;color: #7c7c7c;transition: all .5s ease;
      }
      &:hover{margin-left: 10px;}

    }


  }
  img{ width: 100%;}
}
.section-headline{padding-bottom: 40px;position: relative; text-align: center;
  h3{font-size: 40px;text-transform: capitalize;font-weight:500;line-height: 48px; color: #004166;}
  p{max-width: 560px;font-size: 17px;margin: 0 auto;}
}
.gallery{
   margin: 60px 0;
  .area-under-head1{ position: relative;
    a.media-img {border-bottom: none;
      display: flex;

      img{width: 100%;
        @media(max-width: 767px){
          margin-bottom: 20px; height: auto;
        }
      }
    }


    ul{ display: flex; padding-left: 0; width: 100%; flex-wrap: wrap;
      @media (max-width:767px){
        // display: block;
      }
      li{list-style: none; width: 25%; padding: .5%;
        @media(max-width: 767px){
         width: 50%;
        }
        a{display: flex;
          img{height: 190px; width: 100%; object-fit: cover;}
          &:hover{border-bottom: none;}
        }
      }
      li.lastimage{ position: relative;
        a{
          position: relative;
          &:before{
            content: ""; position: absolute; top: 0; left: 0; background-color: #000; opacity: 0.5; z-index: 1; width: 100%; height: 100%;
          }

        }
        .lastimg-cap{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); color: #fff; z-index: 1; text-align: center;
          h3,h4{font-size: 18px;}
        }
      }
    }
    form{
      label {
        font-size: 15px;
        font-weight: 500;
      }
      input,select {
        width: 100%;
        font-size: 14px;
        height: 52px; border-radius: 25px;padding: 3px 25px 0;

        &::placeholder {
          font-size: 12px;
          transition: all linear 0.2s;
        }

        &:focus {
          border: 1px solid #0d750d;

          &::placeholder {
            transform: translate(-10px, -20px);
            font-size: 10px;
            color: #0d750d;
          }
        }
      }
      textarea {
        width: 100%; resize: none;
        font-size: 14px;
        height: 100px; border-radius: 25px;padding: 30px 25px 0;

        &::placeholder {
          font-size: 12px;
          transition: all linear 0.2s;
        }

        &:focus {
          border: 1px solid #0d750d;

          &::placeholder {
            transform: translate(-10px, -20px);
            font-size: 10px;
            color: #0d750d;
          }
        }
      }
      /* Remove radio buttons */
      /* Set yellow color when star checked */
      /* Set yellow color when star hover */
      .starrating { justify-content: flex-start; width: 360px;
        @media(max-width:767px){
          width: 100%; justify-content: center;
        }
        >input {
          display: none;
          &:checked {
            ~ {
              label {
                color: #28b144;
              }
            }
          }
          &:hover {
            ~ {
              label {
                color: #28b144;
              }
            }
          }
        }
        >label {
          &:before {
            content: "\f1e0";
            margin: 12px;
            font-size: 45px;
            font-family: "Ionicons";
            display: inline-block;
            @media(max-width:767px){
              margin: 6px;
              font-size: 30px;
            }
          }
          color: #9a999b;
          @media(max-width:767px){
            text-align: center;
          }
        }
      }

      .cstm_btn {
        width: 200px;
        height: 48px;
      }
    }
  }
  ul.video-area {
    align-items: center; padding: 0; margin: 0; list-style: none; display:flex ; flex-wrap: wrap;
    li {
      width: 32.4%;clear: both;padding: 0; margin: 0 5px;
      align-items: center;background: #f2f2f2;
      @include media-breakpoint-down(sm) {
        display: inherit;
        margin-bottom: 10px;
      }
      @include media-breakpoint-down(sm) {
        display: inherit;
        margin-bottom: 10px;
      }

      .video-box {
        margin-bottom: 0;
        video {width: 100%;height: 100%;vertical-align: middle;}

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }
  }
}

//............Thank you page..............//
.thankyou-page{
  width: 100%;
 height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .my-container{position: relative;
    padding: 0 150px;
    &::before {height: 90%;top: 25px;left: 50%; transform: translateX(-50%); content: "";position: absolute;
      background-color: #9f9fb3;width: 80%;
      @media(max-width:1024px){
        width: 100%;
      }
    }
    @include media-breakpoint-down(lg){
      padding: 0 100px;
    }
    @include media-breakpoint-down(md){
      padding: 0 50px;
    }
    @include media-breakpoint-down(sm){
      padding: 0 15px;
    }
  }
  .thankyou{position: relative;
    width: 100%;
    background-color: #b4daff;
    padding: 100px 50px;
   
    @include media-breakpoint-down(lg){
      padding: 80px 25px;
    }
    @include media-breakpoint-down(sm){
      padding: 50px 15px;
    }
    h2 {
      font-size: 60px;
      font-weight: 600;
      margin-bottom: 20px;
      @include media-breakpoint-down(md){
        font-size: 45px;
      }
      @include media-breakpoint-down(sm){
        font-size: 36px;
      }
    }
    p{
      font-size: 18px;
      margin-bottom: 20px;
      @include media-breakpoint-down(md){
        font-size: 16px;
      }
    }
  
  }
}
//............Thank you page..............//